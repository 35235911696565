<template>
  <div>
    <div>
      <table class="table table-striped table-bordered" style="margin-bottom: 0px">
        <colgroup>
          <col style="width:20%">
          <col style="width:45%">
          <col style="width:20%">
          <col style="width:15%">
        </colgroup>
        <tbody>
        <tr :class="rowStyleClassFn(order)">
          <td>{{ order.orderNumber }}</td>
          <td>{{ order.forms[0].town ? order.forms[0].town + ', ' : '' }} {{ order.forms[0].address }}</td>
          <td>{{ order.forms[0].phone }}</td>
          <td class="text-center">{{ $t(order.statuses[0].title) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div style="margin-top: 5px">
      <table class="table table-bordered">
        <colgroup>
          <col style="width:15%">
          <col style="width:40%">
          <col style="width:20%">
          <col style="width:5%">
          <col style="width:10%">
          <col style="width:10%">
        </colgroup>
        <tbody>
        <tr v-for="position in order.positions" :key="position.id">
          <td>{{ position.article }}</td>
          <td>{{ position.title }}</td>
          <td>{{ position.priceTagTitle }}</td>
          <td>{{ position.quantity }}</td>
          <td>{{ position.price }}</td>
          <td>{{ position.quantity * position.price }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OrderPositions',
    props: ['order'],
    methods: {
      rowStyleClassFn(row) {
        return row.statuses.length>0 ? row.statuses[0].token : '';
      },
    }
  }
</script>

<style lang="scss">
  tr.under-review {
    background: none repeat scroll 0 0 #FF8293 !important;
  }
  tr.considerated {
    background: none repeat scroll 0 0 #FFFEC9 !important;
  }
  tr.during-delivery {
    background: none repeat scroll 0 0 #BFFFBF !important;
  }
  tr.delivered {
    background: none repeat scroll 0 0 #2df700 !important;
  }
  tr.closed {
    background: none repeat scroll 0 0 #E5F1F4 !important;
  }
  tr.archive {
    background: none repeat scroll 0 0 #ffa3ed !important;
  }
</style>

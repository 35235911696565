<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-card>
        <div slot="header">
          <strong>{{ $t('orders') }} {{ dateString }}</strong>
        </div>
        <order-positions v-for="order in orders" :key="order.id" :order="order" />
      </b-card>
    </div>
  </div>
</template>

<script>
  import {APIService} from "../../services/api";
  import OrderPositions from "./components/OrderPositions";
  import moment from "moment";

  export default {
    name: 'OrdersPositionsList',
    components: {OrderPositions},
    data: () => {
      return {
        dateString: '',
        orders: [],
      }
    },
    computed: {
      selectedOrderIds() {
        const grid = this.$store.getters.GET_GRID('logistic');
        if (grid && grid.selectedRows.length > 0) {
          return grid.selectedRows.map(order => order.id);
        }
        return [];
      },
    },  
    async beforeMount() {
      try {
        const date = this.$store.getters.GET_LOGISTIC_DATE;
        const startDate = moment(date).startOf('day');
        const endDate = moment(startDate).endOf('day');
        this.dateString = moment(date).format('DD.MM.YYYY');
        let query = `order?join=forms&join=managers&join=positions&join=deliveryDates&join=paymentMethods&join=statuses` +
          `&sort=createdAt,DESC&filter=statuses.token||$in||considerated,during_delivery,delivered` + 
          `&filter=deliveryDates.date||$between||${encodeURIComponent(startDate.format())},${encodeURIComponent(endDate.format())}`
        if (this.selectedOrderIds.length > 0) {
          query += `&filter=id||$in||${this.selectedOrderIds.join(',')}`;
        }
        const orders = await APIService.get(query);
        this.orders = orders.filter(order => {
          order.positions = order.positions.filter(position => !position.removed);
          return order;
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>
